import React, { useEffect, useState, useRef } from "react";
import "./index.scss";
const PortretGallery = (props) => {
  const slider = useRef(null);
  const audioInput = useRef(null);
  let [photos, setPhotos] = useState([
    <span key={0}>
      <input type="radio" id="control-0" name="slider-control" defaultChecked  />
      <div className="slidePortret" id="slide-0">
        <img
          className="d-block img-fluid mx-auto h-80"
          src={`/coverGallery/0.jpg`}
          alt={`slide_0`}
        />
      </div>
    </span>,
  ]);
  let [indexes, setIndexes] = useState([]);
  let [index, setIndex] = useState(1);
  let [isSuffled, setIsSuffled] = useState(false);

  const slide = () => {
    if (index > 30) setIndex(0);
    if(index <= 52) {
    document.getElementById("control-" + index).click();
    setIndex(index++);
    } else if(index === 52) {
      props.close()
      audioInput.current.pause()
    }
  };

  useEffect(() => {
    for (let i = 1; i <= 52; i++) {
      setIndexes((oldArray) => [...oldArray, i]);
    }
    setIsSuffled(true);
  }, []);

  useEffect(() => {
    if (isSuffled === true) {
      indexes.map((elem, index) => {
        setPhotos((oldArray) => [
          ...oldArray,
          <span key={index + 1}>
            <input
              type="radio"
              id={`control-${index + 1}`}
              name="slider-control" 
            />
            <div className="slidePortret" id={`slide-${index + 1}`}>
              <img
                className="d-block img-fluid mx-auto h-80 min-80"
                src={`https://inmemoriambucket.s3.us-east-2.amazonaws.com/images/portret/${elem}.jpg`}
                alt={`slide_${elem}`}
              />
            </div>
          </span>,
        ]);
      });
      let time = 3650;
      let sliderInterval = setInterval(slide, time);

      slider.current.addEventListener("mouseenter", () => {
        clearInterval(sliderInterval);
        sliderInterval = null;
      });

      slider.current.addEventListener("mouseleave", () => {
        sliderInterval = setInterval(slide, time);
      })
      return (() => {
        clearInterval(sliderInterval);
        sliderInterval = null;
      })
    }
  }, [isSuffled]);

  return (
    <div>
      <audio controls autoPlay className="d-none" loop ref={audioInput}>
        <source src="/music/portrets_music.mp3" type="audio/mpeg" />
      </audio>
      <div className="slider" ref={slider}>
        {isSuffled ? photos : null}
      </div>
    </div>
  );
};

export default PortretGallery;
