import Modal from 'react-bootstrap/Modal'
const ModalBootstrap = (props) => {
    return (
      <Modal
        {...props}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        className={`h-100 ${props.className} ${props.rotate? 'rotate' : ''} ${props.rotateImg ? 'rotateImg' : ''} ${props.supportingAc? 'supportingAc' : ''} ${props.maximg? 'maximg' : ''} ${props.imgRight? 'imgRight': ''}  ${props.justify? 'justify': ''} ${props.imgCenter ? 'imgCenter' : ''}`}
      >
        <Modal.Header closeButton className="mx-3">
          {props.title ? <Modal.Title id="contained-modal-title-vcenter" >{props.title} </Modal.Title> : null}
         
        </Modal.Header>
        <Modal.Body>
          {props.children}
        </Modal.Body>
      </Modal>
    );
  }

export default ModalBootstrap;
