import React, { useEffect, useState, useRef, createRef } from "react";
import Carousel, { Modal, ModalGateway } from "react-images";
import "./index.scss";

const PuzzleGallery = (props) => {
  const [currentImage, setCurrentImage] = useState(0);
  const [viewerIsOpen, setViewerIsOpen] = useState(false);
  let [photos, setPhotos] = useState([]);
  let [modalGatewayPhotos, setModalGatewayPhotos] = useState([]);

  let [indexes, setIndexes] = useState([]);

  let [isSuffled, setIsSuffled] = useState(false);

  const [elRefs, setElRefs] = useState([]);

  const [isLoaded, setIsLoaded] = useState(false);

  const openLightbox = (index) => {
    // elRefs[index].current.classList.add('invisible')
    setCurrentImage(index);
    setViewerIsOpen(true);
  };

  const closeLightbox = () => {
    setCurrentImage(0);
    setViewerIsOpen(false);
    props.onHide();
  };

  useEffect(() => {
    if (props.show) {
      for (let i = 1; i <= 141; i++) {
        setIndexes((oldArray) => [...oldArray, i]);
      }
      setElRefs((elRefs) =>
        Array(141)
          .fill()
          .map((_, i) => createRef())
      );
      setIsSuffled(true);
      openLightbox(0);
    }
  }, [props.show]);

  useEffect(() => {
    if (isSuffled === true) {
      indexes.map((elem, index) => {
        if (elem != 35 && elem != 41 && elem != 48 && elem != 51) {
          setModalGatewayPhotos((oldArray) => [
            ...oldArray,
            {
              source: `https://inmemoriambucket.s3.us-east-2.amazonaws.com/images/impreuna/${elem}.jpg`,
            },
          ]);
        }
      });
    }
  }, [isSuffled]);

  return (
    <div>
      {props.show ? (
        <audio controls autoPlay className="d-none" loop>
          <source
            src="https://inmemoriambucket.s3.us-east-2.amazonaws.com/music/1+together/choristes.mp3"
            type="audio/mp3"
          />
          <source
            src="https://inmemoriambucket.s3.us-east-2.amazonaws.com/music/1+together/2+Handel.mp3"
            type="audio/mp3"
          />
          <source
            src="https://inmemoriambucket.s3.us-east-2.amazonaws.com/music/1+together/3+Maunier.mp3"
            type="audio/mp3"
          />
        </audio>
      ) : null}

      <ModalGateway>
        {viewerIsOpen ? (
          <Modal onClose={() => closeLightbox()}>
            <Carousel currentIndex={currentImage} views={modalGatewayPhotos} />
          </Modal>
        ) : null}
      </ModalGateway>
    </div>
  );
};

export default PuzzleGallery;
