import React, { useEffect, useState, useRef, createRef } from "react";
import Carousel, { Modal, ModalGateway } from "react-images";
import '../puzzleGallery/index.scss'
import Loader from '../loader/index'
const MosaicGallery = (props) => {
  let [photos, setPhotos] = useState([]);
  let [loaded, setLoaded] = useState(false);
  let [index, setIndex] = useState(1);

  const [currentImage, setCurrentImage] = useState(0);
  const [viewerIsOpen, setViewerIsOpen] = useState(false);
  let [modalGatewayPhotos, setModalGatewayPhotos] = useState([])

  let [indexes, setIndexes] = useState([]);

  let [isSuffled, setIsSuffled] = useState(false);

  const [elRefs, setElRefs] = useState([]);

  const [isLoaded, setIsLoaded] = useState(false);

  const slider = useRef(null);

  const openLightbox = (index) => {
    // elRefs[index].current.classList.add('invisible')
     setCurrentImage(index);
     setViewerIsOpen(true);
   };
 
   const closeLightbox = () => {
    
     setCurrentImage(0);
     setViewerIsOpen(false);
     props.onHide()
   };

  useEffect(() => {
    if(props.show) {
    for (let i = 1; i <= 164; i++) {
        setIndexes(oldArray => [...oldArray, i])
    }
    setElRefs(elRefs => (
        Array(164).fill().map((_, i) => createRef())
    ));
     setIsSuffled(true)
     openLightbox(0)
    }
  }, [props.show]);

  useEffect(() => {
    if(isSuffled === true) {
      indexes.map((elem, index) => {
        if(elem != 142 && elem != 80 && elem != 41 && elem != 132 && elem !== 110 && elem !== 157 ) {
        setModalGatewayPhotos(oldArray => [...oldArray, { source: `https://inmemoriambucket.s3.us-east-2.amazonaws.com/images/mosaicFinal/${elem}.${elem <= 131 || elem == 164 ? 'jpg' : elem > 131 && elem != 164 ? 'png' : 'jpg'}` }])
    }})
    }

  }, [isSuffled])

  // useEffect(() => {
  //   for (let i = 1; i <= 164; i++) {
  //     if(i != 142 && i != 80 && i != 41 && i != 132 ) {
  //         setPhotos((oldArray) => [
  //           ...oldArray,
  //           <span key={`slide-${i}`}>
  //           <input
  //             type="radio"
  //             id={`control-${i}`}
  //             name="slider-control" 
  //           />
  //           <div className="slidePortret" id={`slide-${i}`}>
  //             <img
  //               className="d-block img-fluid mx-auto"
  //               src={`https://inmemoriambucket.s3.us-east-2.amazonaws.com/images/mosaicFinal/${i}.${i <= 131 || i == 164 ? 'jpg' : i > 131 && i != 164 ? 'png' : 'jpg'}`}
  //               alt={`slide_${i}`}
  //             />
  //             <p>{i}</p>
  //           </div>
  //         </span>,
  //         ]);
  //       }
  //     if(i === 164) {
  //       setTimeout(() => setLoaded(true), 1000)
  //     }
  //   }
  //   let sliderInterval = setInterval(() => {
  //     if(index != 142 && index != 80 && index != 41 && index != 132) {
  //       document.getElementById("control-" + index).click();
  //     }
  //     setIndex(index++)
  //   }, 1000)
    
  //     return () => {
  //       clearInterval(sliderInterval);
  //       sliderInterval = null;
  //       setLoaded(false)
  //     }
  // }, []);
  
  return (
    <div>
       {props.show ? 
      <audio controls autoPlay className="d-none" loop>
        <source src="https://inmemoriambucket.s3.us-east-2.amazonaws.com/music/3+mozaic/Gabriel's+oboi.mp3" type="audio/mp3" />
        <source src="https://inmemoriambucket.s3.us-east-2.amazonaws.com/music/3+mozaic/11+clasic.mp3" type="audio/mp3" />
        <source src="https://inmemoriambucket.s3.us-east-2.amazonaws.com/music/3+mozaic/2+Les+Feuilles+Mortes.mp3" type="audio/mp3" />
      </audio> : null}


      <ModalGateway>
       {viewerIsOpen ? (
         <Modal onClose={() => closeLightbox()}>
           <Carousel currentIndex={currentImage} views={modalGatewayPhotos} />
         </Modal>
       ) : null}
     </ModalGateway>
      {/* // <div className="slider" ref={slider}>
      //   <div className="carouselContainer">
      //     {photos}
      //   </div>
        
      // </div> */}
   
    </div>
  );
};

export default MosaicGallery;
