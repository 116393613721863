import React, { useEffect, useState } from "react";
import "./App.scss";
import Pdf from "./components/pdf/pdf.js";
import ModalBootstrap from "./components/ui/Modal";
import PortretGallery from "./components/portretGallery/index";
import MosaicGallery from "./components/mosaicGallery/index";
import PuzzleGallery from "./components/puzzleGallery/index";
import DocViewerComponent from "./components/docViewer/index.js";
import "./anime.js";

// const useStyles = makeStyles((theme) => ({
//   root: {
//     width: "100%",
//     maxWidth: 360,
//     backgroundColor: "transparent",
//   },
//   linkBorder: {
//     borderRadius: "4px",
//     border: "1px solid transparent",
//     "&:hover": {
//       border: "1px solid rgba(0, 0, 0, 0.23)",
//     },
//   },
// }));

const App = () => {
  let [coverOpened, setCoverOpened] = useState(false);
  let [pdfOpened, setPdfOpened] = useState(false);
  let [showList, setShowList] = useState(false);
  let [description, setDescription] = useState(false);
  let [pdfEnglishOpened, setPdfEnglishOpened] = useState(false);
  let [pdfRomanianOpened, setPdfRomanianOpened] = useState(false);
  let [pdfImagesOpened, setPdfImagesOpened] = useState(false);
  let [mosaicOpened, setMosaicOpened] = useState(false);
  let [animationOpened, setAnimationOpened] = useState(false);
  let [thanksOpened, setThanksOpened] = useState(false);
  let [thanksEnglishOpened, setThanksEnglishOpened] = useState(false);
  let [currentTexts, setCurrentTexts] = useState(0);
  let [openCE, setOpenCE] = useState(false);
  let [newEurope, setOpenNewEurope] = useState(false);
  let [parlamentaryDiplomacy, setParlamentaryDiplomacy] = useState(false);
  let [preventConflicts, setPreventConflicts] = useState(false);
  let [closingCircle, setClosingCircle] = useState(false);
  let [circleEnglishOpened, setCircleEnglishOpened] = useState(false);
  let [circleRomanianOpened, setCircleRomanianOpened] = useState(false);
  let [interviewVideo, setInterviewVideo] = useState(false);
  let [lions, setLions] = useState(false);
  let [funeral, setFuneral] = useState(false);
  let [burial, setBurial] = useState(false);
  let [supportingAC, setSuportingAC] = useState(false);
  //const classes = useStyles();
  // const bucketParams = {
  //   Bucket: "inmemoriambucket",
  //   Key: "arn:aws:s3:::inmemoriambucket",
  // };

  // const getBucketData = async () => {
  //   const data = await client.send(new GetObjectCommand(bucketParams));
  // }

  useEffect(() => {
    //getBucketData();
  }, []);

  useEffect(() => {
    if (burial) {
      document.getElementById("videoBurial").play();
    }
  }, [burial]);

  useEffect(() => {
    if (funeral) {
      document.getElementById("videoFuneral").play();
    }
  }, [funeral]);

  useEffect(() => {
    if (interviewVideo) {
      document.getElementById("videoInterview").play();
    }
  }, [interviewVideo]);

  return (
    <div className="layout">
      <div className="layout__backdrop"></div>
      <div className="layout__frontdrop"></div>
      <div className="layout__wrapper">
        <header className="layout__header" style={{ width: "60%" }}>
          <nav>
            <ul className="nav-left nav--header nav--header-1 px-0">
              <img
                src="https://inmemoriambucket.s3.us-east-2.amazonaws.com/cover.jpg"
                className="w-50 mb-2 mt-2 mx-auto d-md-block d-none"
                alt="cover"
                onClick={() => setCoverOpened(true)}
              />
              <img src="top_title.png" className="w-70 mb-2 mx-auto d-block" />
              <li
                className="nav__item nav__item--about d-block mx-auto d-block d-md-none"
                style={{ width: "70%", marginTop: "90%" }}
              >
                <a
                  className="nav__link"
                  href="#0"
                  onClick={() => {
                    setDescription(true);
                    setShowList(false);
                  }}
                >
                  Liliana 1962-2019
                </a>
              </li>
              <li
                className="nav__item nav__item--about d-block mx-auto"
                style={{ width: "70%" }}
              >
                <a
                  className="nav__link"
                  href="#0"
                  onClick={() => {
                    setCoverOpened(true);
                    setShowList(false);
                  }}
                >
                  Portrait
                </a>
              </li>
              <li
                className="nav__item nav__item--clients d-block mx-auto"
                style={{ width: "70%" }}
              >
                <a
                  className="nav__link"
                  href="#0"
                  onClick={() => {
                    setPdfOpened(true);
                    setShowList(false);
                  }}
                >
                  The book
                </a>
              </li>
              <li
                className="nav__item nav__item--services d-block mx-auto"
                style={{ width: "70%" }}
              >
                <div className="row p-0 m-0 align-items-end flex-column">
                  <div className="p-0 m-0" id="circle"></div>
                </div>

                <a
                  className="nav__link"
                  href="#0"
                  onClick={() => {
                    setAnimationOpened(true);
                    setShowList(false);
                  }}
                >
                  Together
                </a>
              </li>
              <li
                className="nav__item nav__item--contact d-block mx-auto"
                style={{ width: "70%" }}
              >
                <div className="row p-0 m-0 align-items-end flex-column">
                  <div className="p-0 m-0" id="circle"></div>
                </div>
                <a
                  className="nav__link"
                  href="#0"
                  onClick={() => {
                    setMosaicOpened(true);
                    setShowList(false);
                  }}
                >
                  Liliana always
                </a>
              </li>
              <li
                className="nav__item nav__item--contact d-block mx-auto"
                style={{ width: "70%" }}
              >
                <div className="row p-0 m-0 align-items-end flex-column">
                  <div className="p-0 m-0" id="circle"></div>
                </div>
                <a
                  className="nav__link"
                  href="#0"
                  onClick={() => setShowList(true)}
                >
                  Perpetual
                </a>
              </li>
              <div className="bottomContainer">
                <div className="mb-3 mt-1 d-block mx-auto ac">
                  <p className="mr-3">Legend: </p>
                  <div className="row p-0 m-0">
                    <div className="p-0 m-0" id="circle"></div>
                    <p className="col-6 p-0 m-0 text-danger">
                      recent additions
                    </p>
                  </div>
                </div>

                <img
                  src="https://inmemoriambucket.s3.us-east-2.amazonaws.com/ac.png"
                  className="mb-3 mt-1 d-block mx-auto ac"
                  alt="cover"
                  onClick={() =>
                    window.open("https://www.memorialsighet.ro/", "_blank")
                  }
                />
                <img
                  src="https://inmemoriambucket.s3.us-east-2.amazonaws.com/blazon.png"
                  className="mb-3 mt-1 d-block mx-auto blazon"
                  alt="cover"
                  onClick={() => window.open("https://www.spx.org")}
                />
              </div>
            </ul>
          </nav>
        </header>
        <div className="coverImage"></div>
        <li
          className="nav__item nav__item--contact d-md-block d-none mx-auto position-absolute"
          style={{ width: "75%", right: 0, top: "60%" }}
          onClick={() => setDescription(true)}
        >
          <a
            className="Candara smallTxt mb-0 link p-1 d-md-block d-none"
            style={{
              textDecoration: "none",
              textAlign: "justify",
              border: "2px solid #6f694b",
              borderRadius: "15px",
              color: "black",
            }}
          >
            Liliana Mirella Hagiopol (1962-2019) was born in Bucharest, Romania,
            a former communist country. She had distinguished achievements in
            her personal life and profession. After the fall of communism,
            Liliana worked in Strasbourg, France, and Bucharest, Romania, for
            the Council of Europe, an international organization upholding human
            rights, democracy, and the rule of law in Europe. She was the
            director of the Center for Information and Documentation of the
            Council of Europe in Bucharest. During the years spent in Bucharest,
            Liliana fully contributed to establishing the Memorial to the
            Victims of Communism and Resistance (the Sighet Memorial). Saddened
            by the state of her country and eager to provide a better life for
            her son, she left her closest friends and career in Romania to
            immigrate to the United States. As a language teacher in the United
            States, Liliana had the great pleasure of passing on her mastery of
            the French language and her love for French culture and civilization
            to the younger generations at St. Pius X High School. She loved her
            students and found happiness in working with them and watching them
            flourish. Liliana often said her time as a teacher was the best in
            her life. A scholarship was established, in 2019, in her name, as a
            sign of appreciation of Liliana Hagiopol’s impact on the Saint Pius
            X community.{" "}
          </a>
        </li>
        <img
          src="https://inmemoriambucket.s3.us-east-2.amazonaws.com/bottom_title.png"
          className="d-block position-absolute bottomTitle"
          alt="cover"
        />

        {showList ? (
          <div className=" position-absolute buttonsSection">
            <div className="pl-2 align-items-center row mx-0 w-100 justify-content-center">
              <div className="col-3 px-0 mx-2">
                <div
                  className={`buttonLink mb-3 ${
                    currentTexts === 1 ? "active" : ""
                  }`}
                >
                  <h1
                    className={"Candara link"}
                    onClick={() => setCurrentTexts(1)}
                  >
                    RO entering the EU
                  </h1>
                </div>
                <div
                  className={`buttonLink mb-3 ${
                    currentTexts === 2 ? "active" : ""
                  }`}
                >
                  <h1
                    className={"Candara link"}
                    onClick={() => setSuportingAC(true)}
                  >
                    Alongside AC
                  </h1>
                </div>
              </div>
              <div className="col-3 px-0 mx-2">
                <div
                  className={`buttonLink mb-3 ${
                    currentTexts === 6 ? "active" : ""
                  }`}
                >
                  <h1
                    className={"Candara link mr-2"}
                    onClick={() => setCurrentTexts(6)}
                  >
                    LMH Scholarship
                  </h1>
                  <div className="p-0 m-0" id="circle"></div>
                </div>
                <div
                  className={`buttonLink mb-3 ${closingCircle ? "active" : ""}`}
                >
                  <h1
                    className={"Candara link mr-2"}
                    onClick={() => setClosingCircle(true)}
                  >
                    Closing the circle
                  </h1>
                  <div className="p-0 m-0" id="circle"></div>
                </div>
              </div>
              <div className="col-3  px-0 mx-2">
                <div
                  className={`buttonLink mb-3 ${
                    currentTexts === 5 ? "active" : ""
                  }`}
                >
                  <h1
                    className={"Candara link"}
                    onClick={() => setCurrentTexts(5)}
                  >
                    Videos
                  </h1>
                </div>
                <div
                  className={`buttonLink mb-3 ${
                    currentTexts === 7 ? "active" : ""
                  }`}
                >
                  <h1
                    onClick={() => setCurrentTexts(7)}
                    className={"Candara link"}
                  >
                    Acknowledgements
                  </h1>
                </div>
              </div>
            </div>

            <ModalBootstrap
              imgRight
              justify
              show={thanksOpened}
              onHide={() => setThanksOpened(false)}
            >
              <DocViewerComponent
                title={
                  "https://inmemoriambucket.s3.us-east-2.amazonaws.com/Multumiri/Multumiri1.docx"
                }
              />
            </ModalBootstrap>
            <ModalBootstrap
              imgRight
              justify
              show={thanksEnglishOpened}
              onHide={() => setThanksEnglishOpened(false)}
            >
              <DocViewerComponent
                title={
                  "https://inmemoriambucket.s3.us-east-2.amazonaws.com/Multumiri/Our+gratitude+goes+to+all+those+who+helped+us1.docx"
                }
              />
            </ModalBootstrap>
            <ModalBootstrap
              show={interviewVideo}
              onHide={() => setInterviewVideo(false)}
            >
              <video
                className="d-block mx-auto w-90"
                controls
                autoplay
                id="videoInterview"
              >
                <source
                  src="https://inmemoriambucket.s3.us-east-2.amazonaws.com/05/videos/Liliana+Interview+2017.mp4"
                  type="video/mp4"
                />
                Your browser does not support HTML video.
              </video>
            </ModalBootstrap>
            <ModalBootstrap
              show={currentTexts === 5}
              onHide={() => setCurrentTexts(0)}
            >
              <p
                className={"Candara link mb-3"}
                onClick={() => setInterviewVideo(true)}
              >
                Interview at SPX
              </p>
              <p className={"Candara link mb-3"} onClick={() => setLions(true)}>
                Between the Lions: Summer/Fall 2019
              </p>
              <p
                className={"Candara link mb-3"}
                onClick={() => setFuneral(true)}
              >
                Funeral home
              </p>
              <p
                className={"Candara link mb-3"}
                onClick={() => setBurial(true)}
              >
                Liliana Hagiopol Burial At Sea
              </p>
            </ModalBootstrap>
            <ModalBootstrap show={funeral} onHide={() => setFuneral(false)}>
              <video
                className="d-block mx-auto w-90"
                controls
                autoplay
                id="videoFuneral"
              >
                <source
                  src="https://inmemoriambucket.s3.us-east-2.amazonaws.com/05/videos/funeralHome.mp4"
                  type="video/mp4"
                />
                Your browser does not support HTML video.
              </video>
            </ModalBootstrap>
            <ModalBootstrap show={burial} onHide={() => setBurial(false)}>
              <video
                className="d-block mx-auto w-90"
                controls
                autoplay
                id="videoBurial"
              >
                <source
                  src="https://inmemoriambucket.s3.us-east-2.amazonaws.com/05/videos/Liliana+Hagiopol+Burial+At+Sea.mp4"
                  type="video/mp4"
                />
                Your browser does not support HTML video.
              </video>
            </ModalBootstrap>
            <ModalBootstrap show={lions} onHide={() => setLions(false)}>
              <img
                alt="lions cover"
                src="https://inmemoriambucket.s3.us-east-2.amazonaws.com/05/videos/Copertabetweenthelions.png"
                className="img-fluid mx-auto d-block"
              />
              <img
                alt="lions"
                src="https://inmemoriambucket.s3.us-east-2.amazonaws.com/05/videos/betweenthelions.png"
                className="img-fluid mx-auto d-block"
              />
              <a
                className="pl-5 mt-2"
                href="https://issuu.com/spx0/docs/btl_with_print_edits"
              >
                Open newspaper
              </a>
            </ModalBootstrap>
            <ModalBootstrap
              justify
              maximg
              supportingAc
              show={supportingAC}
              onHide={() => setSuportingAC(false)}
            >
              <DocViewerComponent
                title={
                  "https://inmemoriambucket.s3.us-east-2.amazonaws.com/05/suporting+AC/supportingAC.docx"
                }
              />
            </ModalBootstrap>
            <ModalBootstrap
              show={currentTexts === 1}
              onHide={() => setCurrentTexts(0)}
            >
              <p className={"Candara"}>Traducerea documentelor Noii Europe</p>
              <ul>
                <li>
                  <p
                    className={"Candara smallTxt mb-0 link"}
                    onClick={() => setOpenCE(true)}
                  >
                    Manualul Consiliului Europei
                  </p>
                </li>
                <li>
                  <p
                    className={"Candara smallTxt mb-0 link"}
                    onClick={() => setOpenNewEurope(true)}
                  >
                    Documentele Noii Europe (ediție trilingva)
                  </p>
                </li>
                <li>
                  <p
                    className={"Candara smallTxt mb-0 link"}
                    onClick={() => setParlamentaryDiplomacy(true)}
                  >
                    Diplomația parlamentara
                  </p>
                </li>
                <li>
                  <p
                    className={"Candara smallTxt mb-0 link "}
                    onClick={() => setPreventConflicts(true)}
                  >
                    Arta prevenirii conflictelor
                  </p>
                </li>
                <li
                  onClick={() =>
                    window.open(
                      "https://www.worldcat.org/title/dictionarul-spiritului-tolerant-indrumar-pentru-o-gandire-a-mileniului-iii/oclc/895686899&referer=brief_results",
                      "_blank"
                    )
                  }
                >
                  <p className={"Candara smallTxt mb-0 link"}>
                    Dicționarul spiritului tolerant: Îndrumar pentru o gândire a
                    mileniului III
                  </p>
                </li>
                <li
                  onClick={() =>
                    window.open(
                      "https://www.worldcat.org/title/manualul-nato-parteneriat-si-cooperare/oclc/895642274&referer=brief_results",
                      "_blank"
                    )
                  }
                >
                  <p className={"Candara smallTxt mb-0 link"}>Manualul NATO</p>
                </li>
              </ul>
            </ModalBootstrap>
            <ModalBootstrap
              rotate
              show={openCE}
              onHide={() => setOpenCE(false)}
            >
              <a
                target="_blank"
                href="http://bncreanga.ebibliophil.ro/carte/manualul-consiliului-europei"
                rel="noreferrer"
              >
                http://bncreanga.ebibliophil.ro/carte/manualul-consiliului-europei
              </a>
              <Pdf title="https://inmemoriambucket.s3.us-east-2.amazonaws.com/Manualul+CE/Carte+2.pdf" />
            </ModalBootstrap>
            <ModalBootstrap
              show={newEurope}
              onHide={() => setOpenNewEurope(false)}
            >
              <Pdf title="https://inmemoriambucket.s3.us-east-2.amazonaws.com/Documentele+noii+Europe/Carte+4.pdf" />
            </ModalBootstrap>
            <ModalBootstrap
              show={parlamentaryDiplomacy}
              onHide={() => setParlamentaryDiplomacy(false)}
            >
              <a
                target="_blank"
                className="d-block"
                href="https://www.targulcartii.ro/heinrich-klebes/diplomatia-parlamentara-1998-687988"
                rel="noreferrer"
              >
                https://www.targulcartii.ro/heinrich-klebes/diplomatia-parlamentara-1998-687988
              </a>
              <a
                target="_blank"
                href="http://www.scribd.com/doc/208985206/Diplomatia-Parlamentara"
                rel="noreferrer"
              >
                http://www.scribd.com/doc/208985206/Diplomatia-Parlamentara
              </a>
              <Pdf title="https://inmemoriambucket.s3.us-east-2.amazonaws.com/Diplomatia+Parlamentara/Carte+3-3.pdf" />
              <img
                className="img-fluid d-block mx-auto"
                src="/diplomatiaparlamentara.png"
              />
            </ModalBootstrap>
            <ModalBootstrap
              show={preventConflicts}
              onHide={() => setPreventConflicts(false)}
            >
              <Pdf title="https://inmemoriambucket.s3.us-east-2.amazonaws.com/Arta+prevenirii+conflictelor/Carte+1.pdf" />
            </ModalBootstrap>
            <ModalBootstrap
              imgRight
              show={circleEnglishOpened}
              justify
              imgCenter
              onHide={() => setCircleEnglishOpened(false)}
            >
              <Pdf title={"/Closing_Cercle.pdf"} />
            </ModalBootstrap>
            <ModalBootstrap
              show={circleRomanianOpened}
              imgRight
              justify
              imgCenter
              onHide={() => setCircleRomanianOpened(false)}
            >
              <DocViewerComponent
                title={
                  "https://inmemoriambucket.s3.us-east-2.amazonaws.com/Inchiderea+cercului/Inchiderea_cercului.docx"
                }
              />
            </ModalBootstrap>
            <ModalBootstrap
              show={currentTexts === 6}
              className="centerImages"
              justify
              onHide={() => setCurrentTexts(0)}
            >
              <DocViewerComponent
                title={"/Liliana_Hagiopol_Scholarship.docx"}
              />
            </ModalBootstrap>
            <ModalBootstrap
              show={closingCircle}
              onHide={() => setClosingCircle(false)}
            >
              <div className="row">
                <div className="d-flex justify-content-center flex-column col text-center">
                  <img
                    onClick={() => setCircleEnglishOpened(true)}
                    className="w-30 mx-auto"
                    src="/english_flag.svg"
                  />
                </div>
                <div className="d-flex justify-content-center flex-column col text-center">
                  <img
                    onClick={() => setCircleRomanianOpened(true)}
                    className="w-30 mx-auto"
                    src="/romanian_flag.svg"
                  />
                </div>
              </div>
            </ModalBootstrap>
            <ModalBootstrap
              show={currentTexts === 7}
              onHide={() => setCurrentTexts(0)}
            >
              <div className="row">
                <div className="d-flex justify-content-center flex-column col text-center">
                  <img
                    onClick={() => setThanksEnglishOpened(true)}
                    className="w-30 mx-auto"
                    src="/english_flag.svg"
                  />
                </div>
                <div className="d-flex justify-content-center flex-column col text-center">
                  <img
                    onClick={() => setThanksOpened(true)}
                    className="w-30 mx-auto"
                    src="/romanian_flag.svg"
                  />
                </div>
              </div>
            </ModalBootstrap>
          </div>
        ) : null}
        <ModalBootstrap show={coverOpened} onHide={() => setCoverOpened(false)}>
          <PortretGallery close={() => setCoverOpened(false)} />
        </ModalBootstrap>
        <ModalBootstrap
          isPdf
          show={pdfEnglishOpened}
          onHide={() => setPdfEnglishOpened(false)}
        >
          <Pdf title="https://inmemoriambucket.s3.us-east-2.amazonaws.com/carte_inmemoriam/BookEn.pdf" />
        </ModalBootstrap>
        <ModalBootstrap
          show={pdfRomanianOpened}
          onHide={() => setPdfRomanianOpened(false)}
        >
          <Pdf title="https://inmemoriambucket.s3.us-east-2.amazonaws.com/carte_inmemoriam/BookRo.pdf" />
        </ModalBootstrap>
        <ModalBootstrap
          isPdf
          show={pdfImagesOpened}
          onHide={() => setPdfImagesOpened(false)}
        >
          <Pdf title="https://inmemoriambucket.s3.us-east-2.amazonaws.com/carte_inmemoriam/BookImages.pdf" />
        </ModalBootstrap>
        <ModalBootstrap show={pdfOpened} onHide={() => setPdfOpened(false)}>
          <div className="row">
            <div className="d-flex justify-content-center flex-column col text-center">
              <img
                onClick={() => setPdfEnglishOpened(true)}
                className="w-30 mx-auto"
                src="/english_flag.svg"
              />
              <p className={"Candara black"}>Carte engleza</p>
            </div>
            <div className="d-flex justify-content-center flex-column col text-center">
              <img
                onClick={() => setPdfRomanianOpened(true)}
                className="w-30 mx-auto"
                src="/romanian_flag.svg"
              />
              <p className={"Candara black"}> Carte romana</p>
            </div>
            <div className="d-flex justify-content-center flex-column col text-center">
              <img
                onClick={() => setPdfImagesOpened(true)}
                style={{ width: "40%" }}
                className="mb-2 mx-auto"
                src=" https://inmemoriambucket.s3.us-east-2.amazonaws.com/imagesBook.jpeg"
              />

              <p className={"Candara black"}>Imagini carte</p>
            </div>
          </div>
        </ModalBootstrap>
        <PuzzleGallery
          show={animationOpened}
          onHide={() => setAnimationOpened(false)}
        />

        <MosaicGallery
          show={mosaicOpened}
          onHide={() => setMosaicOpened(false)}
        />

        {/* <ModalBootstrap
            show={mosaicOpened}
            onHide={() => setMosaicOpened(false)}
        >
            <MosaicGallery/>
        </ModalBootstrap> */}
        <ModalBootstrap
          show={description}
          justify
          onHide={() => setDescription(false)}
        >
          <p>
            Liliana Mirella Hagiopol (1962-2019) was born in Bucharest, Romania,
            a former communist country. She had distinguished achievements in
            her personal life and profession. After the fall of communism,
            Liliana worked in Strasbourg, France, and Bucharest, Romania, for
            the Council of Europe, an international organization upholding human
            rights, democracy, and the rule of law in Europe. She was the
            director of the Center for Information and Documentation of the
            Council of Europe in Bucharest. During the years spent in Bucharest,
            Liliana fully contributed to establishing the Memorial to the
            Victims of Communism and Resistance (the Sighet Memorial). Saddened
            by the state of her country and eager to provide a better life for
            her son, she left her closest friends and career in Romania to
            immigrate to the United States. As a language teacher in the United
            States, Liliana had the great pleasure of passing on her mastery of
            the French language and her love for French culture and civilization
            to the younger generations at St. Pius X High School. She loved her
            students and found happiness in working with them and watching them
            flourish. Liliana often said her time as a teacher was the best in
            her life. A scholarship was established, in 2019, in her name, as a
            sign of appreciation of Liliana Hagiopol’s impact on the Saint Pius
            X community.{" "}
          </p>
        </ModalBootstrap>
      </div>
    </div>
  );
};

export default App;
