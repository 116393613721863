import React from "react";
import PDFViewer from 'mgr-pdf-viewer-react';
import './pdf.scss'
const Pdf = (props) => {
  return (
    <PDFViewer document={{
        url: props.title
      }}/>
  );
};

export default Pdf;
